export const COMPONENTS = {
  ListSubheader: 'ListSubheader',
  ListItem: 'ListItem',
  ListItemMultiline: 'ListItemMultiline',
  ListItemChips: 'ListItemChips',
  ListItemLoading: 'ListItemLoading',
  ListItemInfo: 'ListItemInfo',
  ListButton: 'ListButton',
  ListItemMessage: 'ListItemMessage'
};

export function genBaseItem(
  args = { type: COMPONENTS.ListItem, props: {}, listeners: {} }
) {
  if (!args.type) {
    console.warn('Item type not defined.');
  }
  return args;
}

export function genSubheaderItem(title) {
  return genBaseItem({
    type: COMPONENTS.ListSubheader,
    props: {
      title
    }
  });
}

export function genDefaultItem(props = {}, listeners = {}) {
  return genBaseItem({
    type: COMPONENTS.ListItem,
    props,
    listeners
  });
}

export function genMultilineItem(props = {}, listeners = {}) {
  return genBaseItem({
    type: COMPONENTS.ListItemMultiline,
    props,
    listeners
  });
}

export function genChipsItem(items = [], listeners = {}) {
  return genBaseItem({
    type: COMPONENTS.ListItemChips,
    props: {
      items
    },
    listeners
  });
}

export function genAddItem(props = {}, listeners = {}) {
  return genBaseItem({
    type: COMPONENTS.ListItem,
    props: {
      icon: '$add',
      title: 'Add item',
      ...props
    },
    listeners
  });
}

export function genTitleItem(title, listeners = {}) {
  return genDefaultItem(
    {
      title,
      showAvatar: false
    },
    listeners
  );
}

export function genServiceNameItem(title, listeners = {}) {
  return genDefaultItem(
    {
      title: title,
      icon: '$email'
    },
    listeners
  );
}

export function genShowMoreItem(props = {}, listeners = {}) {
  return {
    type: COMPONENTS.ListItem,
    props: {
      icon: '$down',
      title: 'Show more',
      ...props
    },
    listeners
  };
}

export function genListLoader() {
  return {
    type: COMPONENTS.ListItemLoading
  };
}

export function genDefaultItemWithInfo(props = {}, listeners = {}) {
  return genBaseItem({
    type: COMPONENTS.ListItemInfo,
    props,
    listeners
  });
}

export function genListButton(props = {}, listeners = {}) {
  return genBaseItem({
    type: COMPONENTS.ListButton,
    props,
    listeners
  });
}

export function genListItemMessage(props = {}) {
  return {
    type: COMPONENTS.ListItemMessage,
    props
  };
}
