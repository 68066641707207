export const menuComponents = {
  DropdownMenuItemCheck: 'DropdownMenuItemCheck',
  DropdownMenuItemDefault: 'DropdownMenuItemDefault',
  DropdownMenuItemDivider: 'DropdownMenuItemDivider',
  DropdownMenuItemHeader: 'DropdownMenuItemHeader'
};

export function genMenuItemDefault(
  { id, title, icon = '', disabled = false },
  listeners = {}
) {
  if (typeof listeners === 'function') {
    listeners = {
      click: listeners
    };
  }

  return {
    type: menuComponents.DropdownMenuItemDefault,
    props: {
      id,
      title,
      icon,
      disabled
    },
    listeners
  };
}

export function genMenuItemCheck(
  { id, title, icon = '', iconColor = '', disabled = false, checked = false },
  listeners = {}
) {
  return {
    type: menuComponents.DropdownMenuItemCheck,
    props: {
      id,
      title,
      icon,
      iconColor,
      disabled,
      checked
    },
    listeners
  };
}

export function genMenuItemDivider() {
  return {
    type: menuComponents.DropdownMenuItemDivider
  };
}

export function genMenuItemHeader(title) {
  return {
    type: menuComponents.DropdownMenuItemHeader,
    props: {
      title
    }
  };
}

export function genMenuItemCopy(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'copy',
      icon: '$copy',
      title: 'Copy',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemEdit(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'edit',
      title: 'Edit',
      icon: '$edit',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemExecute(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'edit',
      title: 'Execute',
      icon: '$execute',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemAccess(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'access',
      icon: '$access',
      title: 'Change access',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemDisable(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'disable',
      icon: '$disable',
      title: 'Disable',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemEnable(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'enable',
      icon: '$enabled',
      title: 'Enable',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemEmulation(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'emulation',
      icon: '$emulation',
      title: 'Emulation on',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemUsePreset(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'use_preset',
      icon: '$qrcode',
      title: 'Use preset',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemMove(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'move',
      icon: '$marker_edit',
      title: 'Move',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemChangeForm(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'move',
      icon: '$change_form',
      title: 'Change form',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemMute(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'mute',
      icon: '$mute',
      title: 'Mute',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemUnmute(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'unmute',
      icon: '$unmute',
      title: 'Unmute',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemSend(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'send_notification',
      icon: '$send',
      title: 'Send notification',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemShowHistory(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'show_history',
      icon: '$history',
      title: 'Show history',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemDownload(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'download_notifications',
      icon: '$download',
      title: 'Download',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemDownloadNotifications(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'download_notifications',
      icon: '$download',
      title: 'Download notifications',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemDelete(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'delete',
      icon: '$delete',
      title: 'Delete',
      ...props
    },
    {
      click: handler
    }
  );
}
export function genMenuItemUnlink(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'unlink',
      icon: '$unlink',
      title: 'Unlink',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemAdd(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'add',
      icon: '$add',
      title: 'Add new',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemAddToGroup(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'addToGroup',
      icon: 'mdi-label',
      title: 'Add to group',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemRemoveFromGroup(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'removeFromGroup',
      icon: 'mdi-label-off',
      title: 'Remove from group',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuItemInfo(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'show-info',
      icon: 'mdi-information',
      title: 'Info',
      ...props
    },
    {
      click: handler
    }
  );
}

export function genMenuDynamicProperty(handler = () => {}, props) {
  return genMenuItemDefault(
    {
      id: 'dynamic-property',
      icon: 'mdi-publish',
      title: 'Edit property',
      ...props
    },
    {
      click: handler
    }
  );
}
